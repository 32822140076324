<div class="modal-content">
  <button class="close" type="button" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">×</span></button>
  <div class="modal-body">
    <div class="modal-header"></div>
    <div class="contain p-30">
      <div class="text-center">
        <h3>Welcome to compost admin</h3>
        <p>start your project with developer friendly admin </p>
        <button class="btn btn-primary btn-lg txt-white" type="button" data-dismiss="modal" aria-label="Close"
          (click)="closeModal()">Get Started</button>
      </div>
    </div>
  </div>
</div>