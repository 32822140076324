import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AdminGuard implements CanActivate {
    constructor(public authService: AuthService,
        public router: Router) { }
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        //POR RAFAEL

        // Guard for user is login or not
        let user = JSON.parse(localStorage.getItem('user'));
        if (!user || user === null) {
            this.router.navigate(['/auth/login']);
            return true
        }
        else if (user) {
            if (!Object.keys(user).length) {
                this.router.navigate(['/auth/login']);
                return true
            }
        }
        return true
    }
}
