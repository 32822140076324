import { Injectable, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { Md5 } from 'ts-md5/dist/Md5';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthService implements OnInit {

    private REST_API_SERVER = environment.urlApis;

    public userData: any;

    private _sessionId: string;
    public showLoader: boolean = false;

    constructor(
        public httpClient: HttpClient,
        public router: Router,
        public ngZone: NgZone,
        public toster: ToastrService,
        private cookieService: CookieService) {

    }

    ngOnInit(): void { }


    /**
      * login
      * @param data 
      * @returns 
      */
    public login(data: any) {
        const md5 = new Md5();
        let encrypt = md5.appendStr(data.password).end();
        // console.log(data);
        return this.httpClient.post(this.REST_API_SERVER + "registros/loginAdmin", { "email": data.email, "password": encrypt });



    }


    get isLoggedIn(): boolean {
        const user = JSON.parse(localStorage.getItem('user'));
        return (user != null && user.emailVerified != false) ? true : false;
    }

}