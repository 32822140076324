<div class="page-main-header" [ngClass]="{'open': headerStrech}">
    <div class="main-header-right row">
        <div class="main-header-left d-lg-none">
            <div class="logo-wrapper">
                <a [routerLink]="['']" routerLinkActive="router-link-active"><img src="assets/images/agrodirectcafe-logo.png" alt=""></a>
            </div>
        </div>
        <div class="mobile-sidebar d-block">
            <div class="media-body text-right switch-sm">
                <label class="switch">
          <input id="sidebar-toggle" type="checkbox" [checked]="navServices.sidebarToggle"><span class="switch-state"
          (click)="switchToggle()"></span>
        </label>
            </div>
        </div>
        <div class="vertical-mobile-sidebar"><i class="fa fa-bars sidebar-bar"></i></div>

        <!-- Menu superior -->
        <div style="display: none;">


            <div class="nav-right col left-menu-header" (click)=openMobileNavL()>
                <ul class="nav-menus-left" [ngClass]="{'open': openNavL}">
                    <li class="text-dark" (click)="toggleFullScreen()">
                        <app-feather-icons [icon]="'maximize'"></app-feather-icons>
                    </li>
                    <li>
                        <div class="dropdown" [ngClass]="{show: dropdown}" ngbDropdown>
                            <button class="btn dropdown-toggle" type="button" data-toggle="dropdown" (click)="toggleDropdown()" ngbDropdownToggle>Category
            <span class="mx-2 badge badge-pill badge-primary">2</span></button>
                            <div class="dropdown-menu" [ngClass]="{show: dropdown}" ngbDropdownMenu>
                                <a class="dropdown-item" *ngFor="let category of categories" ngbDropdownItem>{{category}}</a>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="dropdown" ngbDropdown>
                            <button class="btn dropdown-toggle" type="button" data-toggle="dropdown" ngbDropdownToggle>Status<span
              class="mx-2 badge badge-pill badge-primary">7</span></button>
                            <div class="dropdown-menu p-0" ngbDropdownMenu>
                                <ul class="status-dropdown p-0">
                                    <li>
                                        <h6 class="mb-0">Ongoing Projects</h6>
                                    </li>
                                    <li>
                                        <p class="mb-0">HTML5 Validation Report</p>
                                        <div class="progress sm-progress-bar">
                                            <div class="progress-bar bg-primary" role="progressbar" style="width: 80%" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </li>
                                    <li>
                                        <p class="mb-0">Bootstrap Admin Templates</p>
                                        <div class="progress sm-progress-bar">
                                            <div class="progress-bar bg-secondary" role="progressbar" style="width: 60%" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </li>
                                    <li>
                                        <p class="mb-0">Goggle Crome Extension</p>
                                        <div class="progress sm-progress-bar">
                                            <div class="progress-bar bg-success" role="progressbar" style="width: 45%" aria-valuenow="45" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </li>
                                    <li>
                                        <p class="mb-0">Clients Projects</p>
                                        <div class="progress sm-progress-bar">
                                            <div class="progress-bar bg-info" role="progressbar" style="width: 70%" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="dropdown" ngbDropdown>
                            <button class="btn dropdown-toggle" type="button" data-toggle="dropdown" ngbDropdownToggle>Order
            <span class="mx-2 badge badge-pill badge-primary">1</span></button>
                            <div class="dropdown-menu" ngbDropdownMenu>
                                <a class="dropdown-item" href="javascript:void(0)" *ngFor="let paymentOption of paymentOptions" ngbDropdownItem>{{paymentOption}}</a>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="d-xl-none mobile-toggle-left pull-right"><i data-feather="more-horizontal" (click)=openMobileNav()></i></div>
            </div>
            <div class="nav-right col pull-right right-menu">
                <ul class="nav-menus" [ngClass]="{'open': openNav}">
                    <li (click)="ToggleSearch()">
                        <form class="form-inline search-form">
                            <span class="mobile-search search-icon">
            <i class="fa fa-search"></i></span></form>
                    </li>
                    <li>
                        <div class="dropdown" ngbDropdown>
                            <button class="btn dropdown-toggle" type="button" data-toggle="dropdown"><span class="media user-header"
              ngbDropdownToggle>
              <img class="mr-2 rounded-circle img-35" src="assets/images/dashboard/user.png" alt="">
              <span class="media-body">
                <span class="f-12 f-w-600">Elana Saint</span>
                <span class="d-block">Admin</span></span></span></button>
                            <div class="dropdown-menu p-0 profile-dropdown" ngbDropdownMenu>
                                <ul>
                                    <li class="gradient-primary-1">
                                        <h6 class="mb-0">Elana Saint</h6><span>Web Designer</span>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)">
                                            <app-feather-icons [icon]="'user'"></app-feather-icons>Edit Profile
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)">
                                            <app-feather-icons [icon]="'message-square'"></app-feather-icons>Inbox
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)">
                                            <app-feather-icons [icon]="'lock'"></app-feather-icons>Lock Screen
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)">
                                            <app-feather-icons [icon]="'file-text'"></app-feather-icons>Taskboard
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)">
                                            <app-feather-icons [icon]="'settings'"></app-feather-icons>Settings
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)">
                                            <app-feather-icons [icon]="'log-out'"></app-feather-icons>Logout
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="dropdown" ngbDropdown>
                            <button class="btn dropdown-toggle" type="button" data-toggle="dropdown" ngbDropdownToggle><i
              data-feather="bell"></i><span class="notification badge badge-pill badge-danger f-10">2</span></button>
                            <div class="dropdown-menu p-0 notification-dropdown" ngbDropdownMenu>
                                <ul>
                                    <li class="gradient-primary-1">
                                        <h6>Notifications</h6><span>You have 6 unread messages</span>
                                    </li>
                                    <li>
                                        <div class="media">
                                            <div class="notification-icons bg-success mr-3"><i class="mt-0" data-feather="thumbs-up"></i>
                                            </div>
                                            <div class="media-body">
                                                <h6>Someone Likes Your Posts</h6>
                                                <p class="mb-0"> 2 Hours Ago</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="pt-0">
                                        <div class="media">
                                            <div class="notification-icons bg-info mr-3"><i class="mt-0" data-feather="message-circle"></i>
                                            </div>
                                            <div class="media-body">
                                                <h6>3 New Comments</h6>
                                                <p class="mb-0"> 1 Hours Ago</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="bg-light txt-dark"><a href="javascript:void(0)">All </a> notification</li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="dropdown" ngbDropdown>
                            <button class="btn dropdown-toggle mt-1" type="button" data-toggle="dropdown" ngbDropdownToggle>EN</button>
                            <ul class="dropdown-menu language-dropdown" ngbDropdownMenu>
                                <li ngbDropdownItem>
                                    <a href="javascript:void(0)" data-lng="en" (click)="changeLanguage('en')">
                                        <i class="flag-icon flag-icon-is"></i> English</a>
                                </li>
                                <li ngbDropdownItem>
                                    <a href="javascript:void(0)" data-lng="es" (click)="changeLanguage('es')">
                                        <i class="flag-icon flag-icon-um"></i> Spanish</a>
                                </li>
                                <li ngbDropdownItem>
                                    <a href="javascript:void(0)" data-lng="pt" (click)="changeLanguage('pt')">
                                        <i class="flag-icon flag-icon-uy"></i> Portuguese</a>
                                </li>
                                <li ngbDropdownItem>
                                    <a href="javascript:void(0)" data-lng="fr" (click)="changeLanguage('fr')">
                                        <i class="flag-icon flag-icon-nz"></i> French</a>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <a>
                            <app-feather-icons class="right_side_toggle" [icon]="'message-circle'" (click)="right_side_bar()">
                            </app-feather-icons><span class="dot"></span>
                        </a>
                    </li>
                    <li>
                        <div class="dropdown" ngbDropdown>
                            <button class="btn dropdown-toggle" type="button" data-toggle="dropdown" ngbDropdownToggle><i
              data-feather="droplet"></i></button>
                            <div class="dropdown-menu p-0 droplet-dropdown" ngbDropdownMenu>
                                <ul>
                                    <li class="gradient-primary-1 text-center">
                                        <h6>Grid Dashboard</h6><span>Easy Grid inside dropdown</span>
                                    </li>
                                    <li>
                                        <div class="row">
                                            <div class="col-sm-4 col-6 droplet-main"><i data-feather="file-text"></i><span class="d-block">Content</span></div>
                                            <div class="col-sm-4 col-6 droplet-main"><i data-feather="activity"></i><span class="d-block">Activity</span></div>
                                            <div class="col-sm-4 col-6 droplet-main"><i data-feather="users"></i><span class="d-block">Contacts</span></div>
                                            <div class="col-sm-4 col-6 droplet-main"><i data-feather="clipboard"></i><span class="d-block">Reports</span></div>
                                            <div class="col-sm-4 col-6 droplet-main"><i data-feather="anchor"></i><span class="d-block">Automation</span></div>
                                            <div class="col-sm-4 col-6 droplet-main"><i data-feather="settings"></i><span class="d-block">Settings</span></div>
                                        </div>
                                    </li>
                                    <li class="text-center">
                                        <button class="btn btn-primary btn-air-primary">Follows Up</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <form class="form-inline search-full" [ngClass]="{'open':open_search}" *ngIf="open">
                <div class="form-group form-control-plaintext">
                    <input type="text" placeholder="Search.." [class.open]="isOpenMobile" [(ngModel)]="text" [ngModelOptions]="{standalone: true}" (keyup)="searchTerm(text)">
                    <i class="icon-close close-search" (click)="ToggleSearch()"></i>
                    <div class="Typeahead-menu" [class.is-open]="searchResult" *ngIf="menuItems?.length" id="search-outer">
                        <div class="ProfileCard u-cf" *ngFor="let menuItem of menuItems | slice:0:8">
                            <div class="ProfileCard-avatar">
                                <app-feather-icons [icon]="menuItem.icon" class="header-search"></app-feather-icons>
                            </div>
                            <div class="ProfileCard-details">
                                <div class="ProfileCard-realName">
                                    <a [routerLink]="menuItem?.path" class="realname" (click)="removeFix()">{{menuItem?.title}}</a>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="Typeahead-menu" [class.is-open]="searchResultEmpty">
                        <div class="tt-dataset tt-dataset-0">
                            <div class="EmptyMessage">
                                Opps!! There are no result found.
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="d-lg-none mobile-toggle pull-right" (click)="openMobileNav()">
                <app-feather-icons [icon]="'more-horizontal'"></app-feather-icons>
            </div>
        </div>

    </div>
</div>