export class ConfigDB {
	static data = {
		settings: {
			layout_type: 'ltr',
			sidebar: {
				type: 'default',
				body_type: 'default'
			},
			sidebar_setting: 'iconcolor-sidebar',
			sidebar_backround: 'sidebar-img3'
		},
		color: {
			layout_version: 'light',
			color: 'color-6',
			primary_color: '#3949ab',
			secondary_color: '#4fc3f7',
			mix_layout: 'default',
		},
		background_layout: 'default'
	}
}
