import { HostListener, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	constructor() {
		this.onResize();
		if (this.screenWidth < 1199) {
			this.closeSidebar = true;
			this.sidebarToggle = false;
		}
	}

	public screenWidth: any;
	public closeSidebar: boolean = false;
	public sidebarToggle: boolean = true;
	public fullScreen = false;

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		{ path: '/productores/listado', title: 'Panel', icon: 'sliders', type: 'link' },


		{ path: '/productores/listado', title: 'Productores', icon: 'droplet', type: 'link' },

		{ path: '/cooperativas/listado', title: 'Cooperativas', icon: 'droplet', type: 'link' },

		{ path: '/tostadores/listado', title: 'Tostadores', icon: 'droplet', type: 'link' },

		// { path: '/pruebas', title: 'Alertas', icon: 'bell', type: 'link' },
		{ path: '/users', title: 'Usuarios', icon: 'users', type: 'link' },
		{ path: '/lotes', title: 'Lotes', icon: 'users', type: 'link' },
		{ path: '/cotizaciones', title: 'Cotizaciones', icon: 'shopping-cart', type: 'link' },
		{ path: '/muestras', title: 'Muestras', icon: 'shopping-bag', type: 'link' },
		{ path: '/configuracion', title: 'Configuracion', icon: 'settings', type: 'link' },
		{ path: '/salir', title: 'Salir', icon: 'log-out', type: 'link' }

	];
	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);




}
