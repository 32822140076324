<div class="page-wrapper">
    <div class="dark-body-only" id="outer-container">
        <!-- page-wrapper Start-->
        <div class="page-wrapper" [ngClass]="customizer.data.settings.sidebar.type" id="canvas-bookmark">
            <app-header (rightSidebarEvent)="rightSidebar($event)"></app-header>
            <!-- Page Body Start-->
            <div class="page-body-wrapper" [ngClass]="customizer.data.settings.sidebar.body_type">
                <div class="page-sidebar" id="pages-sidebar" [class.open]="navServices.closeSidebar" [ngClass]="customizer.data.settings.sidebar_backround" [attr.sidebar-layout]="this.customizer.data.settings.sidebar_setting">
                    <app-sidebar></app-sidebar>
                </div>
                <div class="page-body">
                    <app-breadcrumb></app-breadcrumb>
                    <main [@animateRoute]="getRouterOutletState(o)">
                        <router-outlet #o="outlet"></router-outlet>
                    </main>
                </div>
                <!-- footer start-->
                <footer class="footer">
                    <app-footer></app-footer>
                </footer>
                <!-- footer End-->
            </div>
            <!-- Page Body End-->
        </div>
        <!-- page-wrapper End-->
    </div>
    <app-customizer></app-customizer>
</div>