import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-salir',
  templateUrl: './salir.component.html',
  styleUrls: ['./salir.component.scss']
})
export class SalirComponent implements OnInit {

  constructor(
    public router: Router,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {
    console.log('salirr')
    localStorage.clear();
    // localStorage.removeItem('user');
    // this.cookieService.deleteAll('user', '/auth/login');
    this.router.navigate(['/auth/login']);

  }

}
