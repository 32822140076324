import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PruebasComponent } from './components/pruebas/pruebas.component';
import { ContentComponent } from './shared/components/layout/content/content.component';
import { content } from './shared/routes/routes';
import { AdminGuard } from './shared/guard/admin.guard';
import { SalirComponent } from './components/salir/salir.component';


const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: '/auth/login',
  //   pathMatch: 'full'
  // },
  {
    path: '',
    redirectTo: '/auth/login',
    pathMatch: 'full'
  },
  {
    path: '',
    component: ContentComponent,
    canActivate: [AdminGuard], //con esta línea me activa el guard para todo. 
    children: content
  },

  {
    path: '**',
    redirectTo: '/auth/login'
  },
  // {
  //   path: 'pruebas',
  //   component: PruebasComponent,
  //   // pathMatch: 'full'
  // },
  // {
  //   path: 'salir',
  //   component: SalirComponent,
  //   // pathMatch: 'full'
  // },

  {
    path: 'auth',
    loadChildren: () => import('./components/auth/auth.module').then(m => m.AuthModule)
  },


];

@NgModule({
  imports: [[RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
  })],
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
