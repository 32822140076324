import { Routes } from '@angular/router';
import { SalirComponent } from 'src/app/components/salir/salir.component';
import { AdminGuard } from '../guard/admin.guard';

export const content: Routes = [
  {
    path: 'sample-page',
    loadChildren: () => import('../../components/sample-page/sample-page.module').then(m => m.SamplePageModule),
    data: {
      title: "Sample-Page",
      breadcrumb: "Sample-Page"
    }
  },
  {
    path: 'pruebas',
    loadChildren: () => import('../../components/pruebas/pruebas.module').then(m => m.PruebasModule),
    data: {
      title: "Pruebas Página",
      breadcrumb: "Pruebas"
    }
  },
  {
    path: 'users',
    loadChildren: () => import('../../components/users/users.module').then(m => m.UsersModule),
    data: {
      title: "Usuarios",
      breadcrumb: "Usuarios"
    }
  },
  {
    path: 'cotizaciones',
    loadChildren: () => import('../../components/cotizaciones/cotizaciones.module').then(m => m.CotizacionesModule),
    data: {
      title: "Cotizaciones",
      breadcrumb: "cotizaciones"
    }
  },
  {
    path: 'muestras',
    loadChildren: () => import('../../components/muestras/muestras.module').then(m => m.MuestrasModule),
    data: {
      title: "Muestras",
      breadcrumb: "muestras"
    }
  },
  {
    path: 'configuracion',
    loadChildren: () => import('../../components/configuracion/configuracion.module').then(m => m.ConfiguracionModule),
    data: {
      title: "Configuración",
      breadcrumb: "Config"
    }
  },
  {
    path: 'salir',
    component: SalirComponent,
    pathMatch: 'full'
  },

  {
    path: 'productores',
    loadChildren: () => import('../../components/productores/productores.module').then(m => m.ProductoresModule),
    canActivate: [AdminGuard],
    data: {
      title: "Productores",
      breadcrumb: "Productores"
    }
  },
  {
    path: 'cooperativas',
    loadChildren: () => import('../../components/cooperativas/cooperativas.module').then(m => m.CooperativasModule),
    data: {
      title: "Cooperativas",
      breadcrumb: "Cooperativas"
    },
  },
  {
    path: 'tostadores',
    loadChildren: () => import('../../components/tostadores/tostadores.module').then(m => m.TostadoresModule),
    data: {
      title: "Tostadores",
      breadcrumb: "Tostadores"
    }
  },
  // {
  //   path: 'lotes/:id',
  //   loadChildren: () => import('../../components/lotes/lotes.module').then(m => m.LotesModule),
  // },

  {
    path: 'lotes',
    loadChildren: () => import('../../components/lotes/lotes.module').then(m => m.LotesModule),
    data: {
      title: "Lotes",
      breadcrumb: "Lotes"
    }
  }

];